<template>
    <v-container>
        <v-row>
            <v-col
                v-for="(filter, index) in filters.filter(element => element.searchable)"
                :key="index"
                cols="4"
            >
                <v-autocomplete
                    v-if="filter.data ? filter.type === 'api' : false"
                    v-model="filter.queryValue"
                    :items="getFilteredData(filter)"
                    :item-text="item => `${item.title}(${item.code})`"
                    return-object
                    :placeholder="`Επέλεξε ${filter.text}`"
                    :label="getPath(filter)"
                    hide-details
                    :menu-props="{closeOnContentClick:true}"
                    @change="setQuery(filter)"
                    :disabled="filter.disabled"
                >
                    <template v-slot:prepend-inner>
                        <v-btn v-if="filter.level > 1" icon small dark color="black" @click="clearPath(filter)">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                </v-autocomplete>
                <v-select
                    v-else-if="filter.type === 'select'"
                    v-model="filter.queryValue"
                    :items="filter.data"
                    :label="`Επέλεξε ${filter.text}`"
                    clearable
                    item-text="displayValue"
                    item-value="value"
                    :disabled="filter.disabled"
                    @change="setQuery(filter)"
                />
                <v-text-field
                    v-else-if="filter.type === 'simple' || filter.type === 'number'"
                    v-model="filter.queryValue"
                    :type="filter.type === 'number' ? 'number' : 'text'"
                    :suffix="filter.type === 'number' ? '€' : ''"
                    :prefix="filter.type === 'number' ? '>=' : ''"
                    clearable
                    :label="filter.text"
                    :disabled="filter.disabled"
                    @input="setQuery(filter)"
                />
                <v-menu
                    v-else-if="filter.type === 'date'"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="filter.meta === 'from' ? computedFromDate : computedToDate"
                            :label="`${filter.text} ${filter.meta === 'from' ? 'Από' : 'Έως'}`"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            :disabled="filter.disabled"
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="date[filter.meta]"
                        @change="setDate(filter, date)"
                    />
                </v-menu>
                <v-menu
                    v-else-if="filter.type === 'createdAtDate'"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="filter.meta === 'from' ? computedCreatedAtFromDate : computedCreatedAtToDate"
                            :label="`${filter.text} ${filter.meta === 'from' ? 'Από' : 'Έως'}`"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            :disabled="filter.disabled"
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="createdAtDate[filter.meta]"
                        @change="setDate(filter, createdAtDate)"
                    />
                </v-menu>
                <v-menu
                    v-else-if="filter.type === 'checkDate'"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="filter.meta === 'from' ? computedCheckFromDate : computedCheckToDate"
                            :label="`${filter.text} ${filter.meta === 'from' ? 'Από' : 'Έως'}`"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            :disabled="filter.disabled"
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="checkDate[filter.meta]"
                        @change="setDate(filter, checkDate)"
                    />
                </v-menu>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="3" align="start">
                <v-select
                    v-model="sortField"
                    :items="filters.filter(element => element.searchable)"
                    label="Ταξινόμηση"
                    return-object
                    clearable
                    @change="setSorting()"
                />
            </v-col>
            <v-col cols="3" align="start">
                <v-btn
                    icon
                    @click="setSorting()"
                >
                    <v-icon> {{ ascending ? 'mdi-sort-ascending' : 'mdi-sort-descending' }} </v-icon>
                </v-btn>
            </v-col>
            <v-col cols="6" align="end">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-7"
                            :loading="loading"
                            v-bind="attrs"
                            small
                            icon
                            v-on="on"
                            @click="clearFilters"
                        >
                            <v-icon color="black">
                                mdi-reload
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Reset filters</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-2"
                            :disabled="shouldDisableSubmit()"
                            :loading="loading"
                            v-bind="attrs"
                            x-large
                            icon
                            v-on="on"
                            @click="$emit('filterResults', query, sort)"
                        >
                            <v-icon color="success">
                                mdi-filter-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Filter results</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import momentTimezone from 'moment-timezone'
    export default {
        props: {
            filters: {
                type: Array,
                default: null
            }
        },
        data: () => {
            return {
                loading: false,
                query: {},
                sort: null,
                date: { from: null, to: null },
                createdAtDate: { from: null, to: null },
                checkDate: { from: null, to: null },
                ascending: true,
                sortField: null,
                disable: false
            }
        },
        computed: {
            computedFromDate() {
                return this.date.from ? momentTimezone(this.date.from).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedToDate() {
                return this.date.to ? momentTimezone(this.date.to).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedCreatedAtFromDate() {
                return this.createdAtDate.from ? momentTimezone(this.createdAtDate.from).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedCreatedAtToDate() {
                return this.createdAtDate.to ? momentTimezone(this.createdAtDate.to).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedCheckFromDate() {
                return this.checkDate.from ? momentTimezone(this.checkDate.from).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedCheckToDate() {
                return this.checkDate.to ? momentTimezone(this.checkDate.to).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            }
        },
        // watch: {
        //     filters: {
        //         handler(value) {
        //             this.$store.dispatch('setFilters', value)
        //         }
        //     }
        // },
        updated() {
            this.$store.dispatch('setFilters', this.filters)
        },
        methods: {
            clearPath(filter) {
                filter.level = 1
                filter.queryValue = null
            },
            setLevel() {
                this.level += 1
            },
            getPath(filter) {
                let path = ''
                for (let index = filter.level - 1; index > 0; index--) {
                    path += `${index === filter.level - 1 ? '' : '/'}${filter.queryValue.title}(${filter.queryValue.code})`
                }
                return path
            },
            getFilteredData(filter) {
                return filter.data.filter((element) => {
                    if (element.level === filter.level) {
                        if (element.parent === null) {
                            return element
                        } else {
                            if (element.parent.id === filter.queryValue.id) {
                                return element
                            }
                        }
                    }
                })
            },
            clearFilters() {
                this.filters.forEach(element => {
                    if (element.hasOwnProperty('queryValue')) {
                        element.queryValue = null
                    }
                })
                this.query = {}
                this.sort = null
                this.date = { from: null, to: null }
                this.createdAtDate = { from: null, to: null }
                this.checkDate = { from: null, to: null }
                this.ascending = true
                this.sortField = null
                this.$emit('reloadFilters')
            },
            shouldDisableSubmit() {
                return false
            },
            setSorting() {
                this.ascending = !this.ascending
                if (this.sortField !== null) {
                    this.sort = `["${this.sortField.value}","${this.ascending ? 'ASC' : 'DESC'}"]`
                } else {
                    this.sort = null
                }
            },
            setDate(filter, date) {
                filter.queryValue = date[filter.meta]
                if (filter.meta === 'from') {
                    if (filter.queryValue !== null) {
                        this.query[filter.meta] = `"${filter.value}Gte":"${momentTimezone(filter.queryValue).tz('Europe/Athens').startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString()}"`
                    } else {
                        delete this.query[filter.meta]
                    }
                } else {
                    if (filter.queryValue !== null) {
                        this.query[filter.meta] = `"${filter.value}Lte":"${momentTimezone(filter.queryValue).tz('Europe/Athens').endOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString()}"`
                    } else {
                        delete this.query[filter.meta]
                    }
                }
            },
            getChargeUnitChildren(chargeUnits, parent) {
                let temp = `${parent.id}`
                chargeUnits.forEach((element) => {
                    if (element.parent?.id === parent.id) {
                        temp += `, ${this.getChargeUnitChildren(chargeUnits, element)}`
                    }
                })
                return temp
            },
            setQuery(filter) {
                if (filter.single) {
                    this.filters.forEach((element) => {
                        if (element.single) {
                            element.disabled = false
                        } else {
                            element.disabled = filter.queryValue
                        }
                    })
                }
                if (filter.type === 'api') {
                    filter.level += 1
                    if (filter.value === 'chargeUnit') {
                        this.query[filter.value] = `"chargeUnitOr":[${this.getChargeUnitChildren(filter.data, filter.queryValue)}]`
                    } else if (filter.queryValue !== null) {
                        this.query[filter.value] = `"${filter.value}":"${filter.queryValue.id}"`
                    } else {
                        delete this.query[filter.value]
                    }
                } else {
                    if (filter.queryValue !== null) {
                        if (filter.value === 'title' || filter.value === 'description') {
                            this.query[filter.value] = `"${filter.value}":"[${filter.queryValue}]"`
                        } else if (filter.value === 'user') {
                            this.query[filter.value] = `"user":{"username":"[${filter.queryValue}]"}`
                        } else if (filter.value === 'amount') {
                            this.query[filter.value] = `"${filter.value}Gte":${filter.queryValue}`
                        } else if (filter.subLevel === 'invoiceNumber') {
                            this.query[filter.value] = `"invoiceNumber":"${filter.queryValue}"`
                        } else if (filter.subLevel === 'checkNumber') {
                            this.query[filter.value] = `"checkNumber":${filter.queryValue}`
                        } else {
                            this.query[filter.value] = `"${filter.value}":"${filter.queryValue}"`
                        }
                    } else {
                        delete this.query[filter.value]
                    }
                }
                this.$store.dispatch('setQuery', this.query)
            }
        }
    }
</script>
